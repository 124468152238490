import Vue from 'vue';
// import { v4 as uuid4 } from 'uuid';
import BrowserSerialPort from 'avrgirl-arduino/lib/browser-serialport';
import BaseSerial from './base-serial';

import {callFunction} from '../../components/common/api';
import {ide_log} from '../../components/common/ide_log';
import {sleepMs} from '../../components/common/ide_log';

const { serial } = navigator;

// navigator.serial.addEventListener('disconnect', (event) => {
//   ide_log("serial_disconnect", "","",0, "",1);
//   console.log("navigator port disconnect");
//   sleepMs(100);
//   location.reload();
// });
      
// navigator.serial.addEventListener('connect', (event) => {
//   //ide_log("ide","serial_connect", "","",0, "",1,"");
//   console.log("navigator port connect");
//   console.log(this);
//   this.Vue.$serial._initSerial();							// try to auto reconnect
//   //location.reload();
// });

// eslint-disable-next-line no-console
console.log('using navserial');
class NavSerial extends BaseSerial {
  constructor() {
    super();
    this.requestRequired = true;
    this.devices = JSON.parse(localStorage.portNames || '[]');
    this._currentDevice = null;
    this.implementation = 'navserial';
    this.handlesSelect = true;

    this._dataHandler = (buff) => {
      this._lastRead = buff;
      // eslint-disable-next-line no-console
      if (this.DEBUG) console.log('read', Buffer.from(buff).toString('hex'));
      if (!this.mute) this.emit('message', buff.toString(this.encoding));
      this.emit('data', buff);
    };
    this._openHandler = (...args) => {
      this.emit('open', ...args);
    };
    this._closeHandler = (...args) => {
      this.emit('close', ...args);
    };

    navigator.serial.addEventListener('connect', async (event) => {
      console.log("navigator usb port connect");
//      console.log( document.hidden );
			if ( ! document.hidden ) {
        console.log( await ide_log( "usb_connect", "","",0, "",1 ) );
      	//this._initSerial();							// try to auto reconnect
      }
    });

    navigator.serial.addEventListener('disconnect', async (event) => {
      console.log("navigator usb port disconnect");
//      console.log( this.isOpen )
      if ( this.isOpen ) {
        console.log( await ide_log( "usb_disconnect", "","",0, "",1 ) );
        await sleepMs(1000);
        location.reload();
      }
    });

    //this._initSerial();
  }

  // eslint-disable-next-line class-methods-use-this
  async _getDevice(value) {
    // const devices = await serial.getPorts();
    // console.log(devices);
    // return devices.find((d) => d.id === value) || null;
    return value;
  }

  async requestDevice( serialFilter = { classCode: 2 } ) {
    window.localStorage.lastNavSerialPort = "{}";							// delete  lastNavSerialPort
//    const device = await serial.requestPort({ classCode: 2 });
    const device = await serial.requestPort( serialFilter );
    await this.setCurrentDevice(device);
    // if (!device.id) device.id = uuid4();
    // if (await this.getDeviceName(device.id)) {
    //   console.log(this.getDeviceName(device.id));
    //   this.setCurrentDevice(device.id);
    // } else {
    //   this.emit('deviceNamePrompt', device.id);
    // }
  }

  async isDevice(value) {
    return !!(await this._getDevice(value));
  }

  _registerSerial(port) {
    this.serial = new BrowserSerialPort(port, {
      baudRate: this.baud,
      autoOpen: false,
    });
    this.serial.on('data', this._dataHandler);
    this.serial.on('open', this._openHandler);
    this.serial.on('close', this._closeHandler);
  }

  _unregisterSerial() {
    this.serial.off('data', this._dataHandler);
    this.serial.off('open', this._openHandler);
    this.serial.off('close', this._closeHandler);
  }

  async setCurrentDevice(value) {
    if (!(await this.isDevice(value))) return;
    if (this.connected) await this.disconnect();
    this._currentDevice = await this._getDevice(value);
    Vue.set(this, 'currentDevice', value);

    if (this.serial) this._unregisterSerial();
    this._registerSerial(value);

    this.emit('currentDevice', value);
    var serialInfo = JSON.stringify(value.getInfo());
    try {
      await this.connect();
      window.localStorage.lastNavSerialPort = JSON.stringify(value.getInfo());

      await console.log( await ide_log("serial_connect", "","",0, serialInfo,1) );
      await sleepMs(500);
    } catch (err) {
      console.log(err.message);
      if (err.message.includes('Access denied.')) {
        this.emit('errorPrompt', 'access_denied');
      }
      // eslint-disable-next-line no-console
      //console.log("connect fail")

      window.localStorage.lastNavSerialPort = "{}";							// open fail ==> delete lastNavSerialPort
      await console.log( await ide_log("serial_connect_err", err.message,"",0, serialInfo,0) );

      this.emit('console.log', err.message);
      console.error(err);

      alert(
        "\nFailed to open serial port.\n\n" + 
        "- Please unplug, then replug the USB cable\n" +
        "- Keep the USB cable still and stable,\n" + 
        "   do not touch/move the USB cable while uploading.\n"
      );

      if ( this.isOpen ) {
        setTimeout( () => {
          location.reload();			// reload page
        }, 2500);
      }
    }
    // eslint-disable-next-line no-console
    if (this.DEBUG) console.log(value, value.getInfo());
  }

  async _initSerial() {
    const { usbProductId, usbVendorId } = JSON.parse(window.localStorage.lastNavSerialPort || '{}');
    if (!usbVendorId || !usbProductId) return;
    const devices = await serial.getPorts();
    const device = devices.find((d) => {
      const info = d.getInfo();
      return usbProductId === info.usbProductId && usbVendorId === info.usbVendorId;
    });
    if (device) await this.setCurrentDevice(device);
  }

  async setDeviceName(value, name) {
    if (!(await this.isDevice(value))) return;
    this.devices.push({ value, name });
    localStorage.portNames = JSON.stringify(this.devices);
    await this.setCurrentDevice(value);
  }
}

export default NavSerial;
